import { default as ai_45form_45builderJoFOixAsM2Meta } from "/home/forge/forms.indiaai.in/client/pages/ai-form-builder.vue?macro=true";
import { default as editQw9UsHcAzaMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexh94wt08d2hMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/index.vue?macro=true";
import { default as index5ZHKYcjsDBMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as index4k2GKc7YejMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharePsz4QWqm5cMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsDe66oM6NN6Meta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsWRe7ZzeEh4Meta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showETtX5brIZ7Meta } from "/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestDPNbU0C9stMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/create/guest.vue?macro=true";
import { default as indexmJBpteJfetMeta } from "/home/forge/forms.indiaai.in/client/pages/forms/create/index.vue?macro=true";
import { default as homeOV5X2igz9MMeta } from "/home/forge/forms.indiaai.in/client/pages/home.vue?macro=true";
import { default as indexn8fXAFsowkMeta } from "/home/forge/forms.indiaai.in/client/pages/index.vue?macro=true";
import { default as loginZriYCJ26i5Meta } from "/home/forge/forms.indiaai.in/client/pages/login.vue?macro=true";
import { default as email2bEifn6Sa2Meta } from "/home/forge/forms.indiaai.in/client/pages/password/email.vue?macro=true";
import { default as _91token_93rbjZudMexhMeta } from "/home/forge/forms.indiaai.in/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingcGNoi1zIWqMeta } from "/home/forge/forms.indiaai.in/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyr2Pqxdj1xSMeta } from "/home/forge/forms.indiaai.in/client/pages/privacy-policy.vue?macro=true";
import { default as registerj0YLdc3eeoMeta } from "/home/forge/forms.indiaai.in/client/pages/register.vue?macro=true";
import { default as accountY2P1d8rKiRMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/account.vue?macro=true";
import { default as adminLkMWODadEaMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/admin.vue?macro=true";
import { default as billingHKnvOTsCCWMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/billing.vue?macro=true";
import { default as connectionsMqiavNIlnKMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/connections.vue?macro=true";
import { default as indexY52cn8aEm0Meta } from "/home/forge/forms.indiaai.in/client/pages/settings/index.vue?macro=true";
import { default as password0XXb0wsbctMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/password.vue?macro=true";
import { default as profilenxHqwEMptEMeta } from "/home/forge/forms.indiaai.in/client/pages/settings/profile.vue?macro=true";
import { default as workspaceYp8HSlZDI5Meta } from "/home/forge/forms.indiaai.in/client/pages/settings/workspace.vue?macro=true";
import { default as settingsp4yhzLkSWmMeta } from "/home/forge/forms.indiaai.in/client/pages/settings.vue?macro=true";
import { default as errormN6wz2Y7ywMeta } from "/home/forge/forms.indiaai.in/client/pages/subscriptions/error.vue?macro=true";
import { default as successVp7BBKQeAjMeta } from "/home/forge/forms.indiaai.in/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93w1kZAO4eR8Meta } from "/home/forge/forms.indiaai.in/client/pages/templates/[slug].vue?macro=true";
import { default as indexv0UlOEFFTmMeta } from "/home/forge/forms.indiaai.in/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93ym3yymIrHEMeta } from "/home/forge/forms.indiaai.in/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templates32jorC4FiHMeta } from "/home/forge/forms.indiaai.in/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93zG9pwwrxtGMeta } from "/home/forge/forms.indiaai.in/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionseVhh8gmuxuMeta } from "/home/forge/forms.indiaai.in/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45builderJoFOixAsM2Meta?.name ?? "ai-form-builder",
    path: ai_45form_45builderJoFOixAsM2Meta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builderJoFOixAsM2Meta || {},
    alias: ai_45form_45builderJoFOixAsM2Meta?.alias || [],
    redirect: ai_45form_45builderJoFOixAsM2Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editQw9UsHcAzaMeta?.name ?? "forms-slug-edit",
    path: editQw9UsHcAzaMeta?.path ?? "/forms/:slug()/edit",
    meta: editQw9UsHcAzaMeta || {},
    alias: editQw9UsHcAzaMeta?.alias || [],
    redirect: editQw9UsHcAzaMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexh94wt08d2hMeta?.name ?? "forms-slug",
    path: indexh94wt08d2hMeta?.path ?? "/forms/:slug()",
    meta: indexh94wt08d2hMeta || {},
    alias: indexh94wt08d2hMeta?.alias || [],
    redirect: indexh94wt08d2hMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showETtX5brIZ7Meta?.name ?? undefined,
    path: showETtX5brIZ7Meta?.path ?? "/forms/:slug()/show",
    meta: showETtX5brIZ7Meta || {},
    alias: showETtX5brIZ7Meta?.alias || [],
    redirect: showETtX5brIZ7Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: index5ZHKYcjsDBMeta?.name ?? "forms-slug-show",
    path: index5ZHKYcjsDBMeta?.path ?? "",
    meta: index5ZHKYcjsDBMeta || {},
    alias: index5ZHKYcjsDBMeta?.alias || [],
    redirect: index5ZHKYcjsDBMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: index4k2GKc7YejMeta?.name ?? "forms-slug-show-integrations",
    path: index4k2GKc7YejMeta?.path ?? "integrations",
    meta: index4k2GKc7YejMeta || {},
    alias: index4k2GKc7YejMeta?.alias || [],
    redirect: index4k2GKc7YejMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: sharePsz4QWqm5cMeta?.name ?? "forms-slug-show-share",
    path: sharePsz4QWqm5cMeta?.path ?? "share",
    meta: sharePsz4QWqm5cMeta || {},
    alias: sharePsz4QWqm5cMeta?.alias || [],
    redirect: sharePsz4QWqm5cMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: statsDe66oM6NN6Meta?.name ?? "forms-slug-show-stats",
    path: statsDe66oM6NN6Meta?.path ?? "stats",
    meta: statsDe66oM6NN6Meta || {},
    alias: statsDe66oM6NN6Meta?.alias || [],
    redirect: statsDe66oM6NN6Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionsWRe7ZzeEh4Meta?.name ?? "forms-slug-show-submissions",
    path: submissionsWRe7ZzeEh4Meta?.path ?? "submissions",
    meta: submissionsWRe7ZzeEh4Meta || {},
    alias: submissionsWRe7ZzeEh4Meta?.alias || [],
    redirect: submissionsWRe7ZzeEh4Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: guestDPNbU0C9stMeta?.name ?? "forms-create-guest",
    path: guestDPNbU0C9stMeta?.path ?? "/forms/create/guest",
    meta: guestDPNbU0C9stMeta || {},
    alias: guestDPNbU0C9stMeta?.alias || [],
    redirect: guestDPNbU0C9stMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: indexmJBpteJfetMeta?.name ?? "forms-create",
    path: indexmJBpteJfetMeta?.path ?? "/forms/create",
    meta: indexmJBpteJfetMeta || {},
    alias: indexmJBpteJfetMeta?.alias || [],
    redirect: indexmJBpteJfetMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homeOV5X2igz9MMeta?.name ?? "home",
    path: homeOV5X2igz9MMeta?.path ?? "/home",
    meta: homeOV5X2igz9MMeta || {},
    alias: homeOV5X2igz9MMeta?.alias || [],
    redirect: homeOV5X2igz9MMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexn8fXAFsowkMeta?.name ?? "index",
    path: indexn8fXAFsowkMeta?.path ?? "/",
    meta: indexn8fXAFsowkMeta || {},
    alias: indexn8fXAFsowkMeta?.alias || [],
    redirect: indexn8fXAFsowkMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginZriYCJ26i5Meta?.name ?? "login",
    path: loginZriYCJ26i5Meta?.path ?? "/login",
    meta: loginZriYCJ26i5Meta || {},
    alias: loginZriYCJ26i5Meta?.alias || [],
    redirect: loginZriYCJ26i5Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: email2bEifn6Sa2Meta?.name ?? "password-email",
    path: email2bEifn6Sa2Meta?.path ?? "/password/email",
    meta: email2bEifn6Sa2Meta || {},
    alias: email2bEifn6Sa2Meta?.alias || [],
    redirect: email2bEifn6Sa2Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93rbjZudMexhMeta?.name ?? "password-reset-token",
    path: _91token_93rbjZudMexhMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93rbjZudMexhMeta || {},
    alias: _91token_93rbjZudMexhMeta?.alias || [],
    redirect: _91token_93rbjZudMexhMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingcGNoi1zIWqMeta?.name ?? "pricing",
    path: pricingcGNoi1zIWqMeta?.path ?? "/pricing",
    meta: pricingcGNoi1zIWqMeta || {},
    alias: pricingcGNoi1zIWqMeta?.alias || [],
    redirect: pricingcGNoi1zIWqMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyr2Pqxdj1xSMeta?.name ?? "privacy-policy",
    path: privacy_45policyr2Pqxdj1xSMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyr2Pqxdj1xSMeta || {},
    alias: privacy_45policyr2Pqxdj1xSMeta?.alias || [],
    redirect: privacy_45policyr2Pqxdj1xSMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerj0YLdc3eeoMeta?.name ?? "register",
    path: registerj0YLdc3eeoMeta?.path ?? "/register",
    meta: registerj0YLdc3eeoMeta || {},
    alias: registerj0YLdc3eeoMeta?.alias || [],
    redirect: registerj0YLdc3eeoMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsp4yhzLkSWmMeta?.name ?? undefined,
    path: settingsp4yhzLkSWmMeta?.path ?? "/settings",
    meta: settingsp4yhzLkSWmMeta || {},
    alias: settingsp4yhzLkSWmMeta?.alias || [],
    redirect: settingsp4yhzLkSWmMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: accountY2P1d8rKiRMeta?.name ?? "settings-account",
    path: accountY2P1d8rKiRMeta?.path ?? "account",
    meta: accountY2P1d8rKiRMeta || {},
    alias: accountY2P1d8rKiRMeta?.alias || [],
    redirect: accountY2P1d8rKiRMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminLkMWODadEaMeta?.name ?? "settings-admin",
    path: adminLkMWODadEaMeta?.path ?? "admin",
    meta: adminLkMWODadEaMeta || {},
    alias: adminLkMWODadEaMeta?.alias || [],
    redirect: adminLkMWODadEaMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingHKnvOTsCCWMeta?.name ?? "settings-billing",
    path: billingHKnvOTsCCWMeta?.path ?? "billing",
    meta: billingHKnvOTsCCWMeta || {},
    alias: billingHKnvOTsCCWMeta?.alias || [],
    redirect: billingHKnvOTsCCWMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsMqiavNIlnKMeta?.name ?? "settings-connections",
    path: connectionsMqiavNIlnKMeta?.path ?? "connections",
    meta: connectionsMqiavNIlnKMeta || {},
    alias: connectionsMqiavNIlnKMeta?.alias || [],
    redirect: connectionsMqiavNIlnKMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: indexY52cn8aEm0Meta?.name ?? "settings",
    path: indexY52cn8aEm0Meta?.path ?? "",
    meta: indexY52cn8aEm0Meta || {},
    alias: indexY52cn8aEm0Meta?.alias || [],
    redirect: indexY52cn8aEm0Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: password0XXb0wsbctMeta?.name ?? "settings-password",
    path: password0XXb0wsbctMeta?.path ?? "password",
    meta: password0XXb0wsbctMeta || {},
    alias: password0XXb0wsbctMeta?.alias || [],
    redirect: password0XXb0wsbctMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profilenxHqwEMptEMeta?.name ?? "settings-profile",
    path: profilenxHqwEMptEMeta?.path ?? "profile",
    meta: profilenxHqwEMptEMeta || {},
    alias: profilenxHqwEMptEMeta?.alias || [],
    redirect: profilenxHqwEMptEMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaceYp8HSlZDI5Meta?.name ?? "settings-workspace",
    path: workspaceYp8HSlZDI5Meta?.path ?? "workspace",
    meta: workspaceYp8HSlZDI5Meta || {},
    alias: workspaceYp8HSlZDI5Meta?.alias || [],
    redirect: workspaceYp8HSlZDI5Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errormN6wz2Y7ywMeta?.name ?? "subscriptions-error",
    path: errormN6wz2Y7ywMeta?.path ?? "/subscriptions/error",
    meta: errormN6wz2Y7ywMeta || {},
    alias: errormN6wz2Y7ywMeta?.alias || [],
    redirect: errormN6wz2Y7ywMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successVp7BBKQeAjMeta?.name ?? "subscriptions-success",
    path: successVp7BBKQeAjMeta?.path ?? "/subscriptions/success",
    meta: successVp7BBKQeAjMeta || {},
    alias: successVp7BBKQeAjMeta?.alias || [],
    redirect: successVp7BBKQeAjMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w1kZAO4eR8Meta?.name ?? "templates-slug",
    path: _91slug_93w1kZAO4eR8Meta?.path ?? "/templates/:slug()",
    meta: _91slug_93w1kZAO4eR8Meta || {},
    alias: _91slug_93w1kZAO4eR8Meta?.alias || [],
    redirect: _91slug_93w1kZAO4eR8Meta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexv0UlOEFFTmMeta?.name ?? "templates",
    path: indexv0UlOEFFTmMeta?.path ?? "/templates",
    meta: indexv0UlOEFFTmMeta || {},
    alias: indexv0UlOEFFTmMeta?.alias || [],
    redirect: indexv0UlOEFFTmMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ym3yymIrHEMeta?.name ?? "templates-industries-slug",
    path: _91slug_93ym3yymIrHEMeta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93ym3yymIrHEMeta || {},
    alias: _91slug_93ym3yymIrHEMeta?.alias || [],
    redirect: _91slug_93ym3yymIrHEMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templates32jorC4FiHMeta?.name ?? "templates-my-templates",
    path: my_45templates32jorC4FiHMeta?.path ?? "/templates/my-templates",
    meta: my_45templates32jorC4FiHMeta || {},
    alias: my_45templates32jorC4FiHMeta?.alias || [],
    redirect: my_45templates32jorC4FiHMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zG9pwwrxtGMeta?.name ?? "templates-types-slug",
    path: _91slug_93zG9pwwrxtGMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93zG9pwwrxtGMeta || {},
    alias: _91slug_93zG9pwwrxtGMeta?.alias || [],
    redirect: _91slug_93zG9pwwrxtGMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionseVhh8gmuxuMeta?.name ?? "terms-conditions",
    path: terms_45conditionseVhh8gmuxuMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionseVhh8gmuxuMeta || {},
    alias: terms_45conditionseVhh8gmuxuMeta?.alias || [],
    redirect: terms_45conditionseVhh8gmuxuMeta?.redirect,
    component: () => import("/home/forge/forms.indiaai.in/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]